import { error } from "jquery";
import { FETCH_CHARGER_REPORT_API, CHARGING_SERVICE_URL, PREDICTIVE_URL, FETCH_DAILY_REPORT_API, FETCH_ALL_REPORT_API, RELEASE_TRANSACTION_API, REPORT_PERCENTAGE_CHANGE_API, REVENUE_SHARING_LIST_API, REVENUE_TOP10_TRANSACTION_API, FETCH_PLUG_UNPLUG_REPORT, FETCH_METER_DATA_BY_ID_API, COMMAND_URL, TOP_10_FAILED_TRANSACTION_API, TOP_10_COMPLETED_TRANSACTION_API, TOP_10_SUCCESS_TRANSACTION_API, FETCH_SUCCESS_TRANSACTION_API, FETCH_FAILED_TRANSACTION_API, FETCH_SINGLE_REPORT_RFID_API, WALLET_URL, FETCH_MY_REPORT_CARD_COUNT_API, FETCH_RFID_CHARGING_HISTORY_API, FETCH_RFID_REPORT_API, CUSTOMER_URL } from "../../components/config/config";
import { ApiHandler } from "./apiHandler";


export const fetchChargerReport = (currentPage, pageSize) => {
  // console.log("current page: " + currentPage)
  // console.log("page size: " + pageSize)
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_CHARGER_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("LOAD_CHARGER_REPORT_API", data)
      dispatch({ type: 'LOAD_CHARGER_REPORT_API', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllChargerReportAction = (currentPage, pageSize) => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_CHARGER_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("FETCH_ALL_CHARGER__REPORT_DATA_REDUCER", data)
      dispatch({ type: 'FETCH_ALL_CHARGER__REPORT_DATA_REDUCER', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchDailyReport = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_DAILY_REPORT_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_DAIL_REPORT', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchMyReportReport = (start, end, org, currentPage, pageSize) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_ALL_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'LOAD_ALL_REPORT', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchReportCsvData = (start, end, org, currentPage, pageSize) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  // console.log("currentPage action", currentPage)
  // console.log("pageSize action", pageSize);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    // console.log("csv data", PREDICTIVE_URL + FETCH_ALL_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize)
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_ALL_REPORT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_REPORT_CSV', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchPlugUnplugsReport = (start, end, org, currentPage, pageSize) => {
  // console.log("start,end,org",start,end,org)
  const { token } = JSON.parse(localStorage.getItem('user'));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    // console.log(PREDICTIVE_URL + FETCH_PLUG_UNPLUG_REPORT + "?start=" + start + "&end=" + end + "&operator=" + org)
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_PLUG_UNPLUG_REPORT + "?start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'GET_PLUG_UNPLUG_REPORT', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllPlugUnplugsReportDataAction = (start, end, org, currentPage, pageSize) => {
  // console.log("start,end,org",start,end,org)
  const { token } = JSON.parse(localStorage.getItem('user'));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    // console.log(PREDICTIVE_URL + FETCH_PLUG_UNPLUG_REPORT + "?start=" + start + "&end=" + end + "&operator=" + org)
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_PLUG_UNPLUG_REPORT + "?start=" + start + "&end=" + end + "&operator=" + org + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("FETCH_ALL_PLUG_UNPLUG_DATA_REDUCER", data)
      dispatch({ type: 'FETCH_ALL_PLUG_UNPLUG_DATA_REDUCER', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchTop10Transaction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + REVENUE_TOP10_TRANSACTION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'TOP10_REVENUE_SHARING_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const releaseTransaction = (charging_id) => {
  // console.log(charging_id)
  // console.log(CHARGING_SERVICE_URL+RELEASE_TRANSACTION_API+"?charging_id="+charging_id)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(CHARGING_SERVICE_URL + RELEASE_TRANSACTION_API + "?charging_id=" + charging_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'RELEASE_TRANSACTION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const reportPercentageChange = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { token, organisation, role } = user;
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token

  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' });

    try {
      const response = await fetch(
        `${PREDICTIVE_URL}${REPORT_PERCENTAGE_CHANGE_API}?operator=${operator}`,
        {
          method: "GET",
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        }
      );

      if (!response.ok) {
        // If response is not ok, throw an error to be caught in the catch block
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("")
      dispatch({ type: 'REPORT_PERCENTAGE_CHANGE', payload: data });
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Error fetching station session data:', error);
      // Optionally, dispatch an error action to handle errors in the UI
      // dispatch({ type: 'LOAD_TOTAL_STATION_CONSUMPTION', payload: error.message });
    }
  };
};

export const fetchRevenueSharingReport = (start, end, currentPage, pageSize) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + REVENUE_SHARING_LIST_API + "?start=" + start + "&end=" + end + "&operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      // const data = await ApiHandler("http://192.168.1.26:7009/predictive/secure/api/v1/revenue_sharing_report" + "?start=" + start + "&end=" + end + "&operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("REVENUE_SHARING_LIST", data)
      dispatch({ type: 'REVENUE_SHARING_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllRevenueSharingReportDataAction = (start, end, currentPage, pageSize) => {
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + REVENUE_SHARING_LIST_API + "?start=" + start + "&end=" + end + "&operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      // const data = await ApiHandler("http://192.168.1.26:7009/predictive/secure/api/v1/revenue_sharing_report" + "?start=" + start + "&end=" + end + "&operator=" + operator + "&page=" + currentPage + "&limit=" + pageSize, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("FETCH_ALL_REVENUE_SHARING_LIST_REDUCER", data)
      dispatch({ type: 'FETCH_ALL_REVENUE_SHARING_LIST_REDUCER', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchMeterValueById = (charging_id, tag_id) => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(COMMAND_URL + FETCH_METER_DATA_BY_ID_API + "?transaction_id=" + charging_id + "&idTag=" + tag_id, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_METER_DATA_BY_ID', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchTop10FailedTransaction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + TOP_10_FAILED_TRANSACTION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'TOP10_FAILED_TRANSACTION_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchTop10SuccessTransaction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + TOP_10_SUCCESS_TRANSACTION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'TOP10_SUCCESS_TRANSACTION_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// success transaction Api 
export const fetchSuccessTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_SUCCESS_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_SUCCESS_TRANSACTION_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}
// success all transaction api
export const fetchAllSuccessTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_SUCCESS_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_All_SUCCESS_TRANSACTION_LIST', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}
// failed transaction api
export const fetchFailedTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    // console.log(PREDICTIVE_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit)
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    if (data) {
      // console.log("failed transaction report", data)
      dispatch({ type: 'FETCH_FAILED_TRANSACTION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchAllFailedTransactionAction = (startDate, endDate, org, page, limit) => {
  // console.log(startDate, endDate, org, page, limit)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    if (data) {
      // console.log("dwadada", data)
      dispatch({ type: 'FETCH_ALL_FAILED_TRANSACTION', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

export const fetchSingleReportRfidAction = (rfid) => {
  // console.log(rfid)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    try {
      const response = await fetch(`${WALLET_URL + FETCH_SINGLE_REPORT_RFID_API}?rfid=${rfid}`, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      // Check if the response is OK
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("data", data)
      dispatch({ type: 'FETCH_SINGLE_REPORT_RFID_API', payload: data });

    } catch (error) {
      console.error("Error fetching single report rfid:", error);
      // dispatch({ type: 'FETCH_SINGLE_REPORT_RFID_API', payload: error.message });
    }
  }
}

// Fetch my report card count
export const fetchMyReportCardCountAction = (start, end, org) => {
  const { token, role, region } = JSON.parse(localStorage.getItem('user'));
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";
  const bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    const data = await ApiHandler(PREDICTIVE_URL + FETCH_MY_REPORT_CARD_COUNT_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&start=" + start + "&end=" + end + "&operator=" + org, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    if (data) {
      // console.log("my report card count", data)
      dispatch({ type: 'FETCH_MY_REPORT_CARD_COUNT', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}

// FETCH RFID CHARGING HISTORY API's
export const fetchRfidChargingHistoryAction = (rfid, startDate, endDate) => {
  // console.log(rfid)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');   // Ensure 2 digits for day
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Convert the start and end dates to dd-mm-yyyy format
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    try {
      // console.log(`${"http://192.168.1.23:7003" + FETCH_RFID_CHARGING_HISTORY_API}?rfid=${rfid}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`)
      const response = await fetch(`${CHARGING_SERVICE_URL + FETCH_RFID_CHARGING_HISTORY_API}?rfid=${rfid}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`, {
        method: "GET",
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      // Check if the response is OK
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("data", data)
      dispatch({ type: 'FETCH_RFID_CHARGING_HISTORY', payload: data });

    } catch (error) {
      console.error("Error fetching single report rfid:", error);
      // dispatch({ type: 'FETCH_SINGLE_REPORT_RFID_API', payload: error.message });
    }
  }
}

// Fetch Rfid Report api
export const fetchRfidReportAction = (org, startDate, endDate) => {
  // console.log(startDate, endDate, org)
  const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
  // const operator = role === "Admin" ? "All" : organisation;
  var bearer = 'basic ' + token;
  return async dispatch => {
    dispatch({ type: 'REPORT_LOADING', payload: '' })
    // console.log(WALLET_URL + FETCH_RFID_REPORT_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate)
    const data = await ApiHandler(CHARGING_SERVICE_URL + FETCH_RFID_REPORT_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    if (data) {
      // console.log("FETCH_RFID_REPORT_REDUCER", data)
      dispatch({ type: 'FETCH_RFID_REPORT_REDUCER', payload: data })
    } else {
      console.log("No data found from server")
    }
  }
}