import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import ChargerReportTable from "./adminReport/chargerReportTable";
import { fetchAllChargerReportAction, fetchChargerReport } from "../../../store/actions/reportActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../breadcrums";
import { useTranslation } from "react-i18next";
import ChargerReportPdf from "./chargerReportPdf";

function ChargerReport(props) {
    const { t } = useTranslation();

    useEffect(() => {
        props.getAllChargerReportDataApi(1, 10000000);
    }, [])

    const allData = props.fetchAllChargerReportRes ? props.fetchAllChargerReportRes.data : [];
    // console.log("data", data);

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <Row>
                <Col xs={12} md={12}>
                    <Card className="customercard mt-3">
                        <Card.Header>
                            <Row className="align-items-center">
                                <Col xs={6} md={6} className="rfid-test">
                                    {t("Charger Report")}
                                </Col>

                                <Col xs={6} md={6} className="text-right">
                                    <ChargerReportPdf {...props} allData={allData} />
                                </Col>
                            </Row>
                        </Card.Header>
                        <ChargerReportTable {...props} allData={allData} />
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        fetchAllChargerReportRes: state.report.fetchAllChargerReport,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllChargerReportDataApi: (currentPage, pageSize) => dispatch(fetchAllChargerReportAction(currentPage, pageSize)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChargerReport);
