import { Container, Card } from "react-bootstrap";
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SingleCustomerWalletAction } from "../../../../store/actions/walletActions";
import PureBreadcrumbs from "../../breadcrums";
import moment from "moment";
import "../customer.css"
import { t } from "i18next";

function SingleWallet(props) {
    const { userid } = useParams()

    useEffect(() => {
        props.getSingleWalletData(userid);
    }, []);

    const data = props.fetchSinglWalletDetails && props.fetchSinglWalletDetails.wallet
    // console.log(data)
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <div className="row">
                <div className="col-lg-12 col-sm-12 pad_t2">
                    <span style={{ color: "var(--text-primary)", fontWeight: "bold" }}> {t("User Wallet Details")} </span>
                </div>
            </div>

            <div className="row pad_t1">
                <div className="col">
                    <Card className="tariffcard">
                        <Card.Body>
                            <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Username")}</label>
                                        <input className="form-control gray-border" type="text" name="UserID" id="floatingInputGridName" placeholder={t("Username")} defaultValue={data.userid} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Firstname")}</label>
                                        <input className="form-control gray-border" type="text" name="firstname" id="floatingInputGridName" placeholder={t("Firstname")} defaultValue={data.firstname} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Lastname")}</label>
                                        <input className="form-control gray-border" type="text" name="lastname" id="floatingInputGridName" placeholder={t("Lastname")} defaultValue={data.lastname} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row pad_t1">
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{("Amount")}</label>
                                        <input className="form-control gray-border" type="text" name="amount" id="floatingInputGridName" placeholder={("Amount")} defaultValue={data.amount} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div>
                                        <label htmlFor="floatingInputGridName">{t("Account Type")}</label>
                                        <input className="form-control gray-border" type="text" name="type" id="floatingInputGridName" placeholder={t("Account Type")} defaultValue={data.type} disabled />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    {/* <div>
                                        <label htmlFor="floatingInputGridName">Created Date</label>
                                        <input className="input" type="text" name="registerat" id="floatingInputGridName" placeholder="Created Date" defaultValue={data.registerat} disabled />
                                    </div> */}
                                </div>
                            </div>
                        </Card.Body>
                        <br />
                        <br />
                        <Card.Title>
                            {t("All Transaction History")}
                        </Card.Title>
                        <Card >
                            <div className="table-responsive ">
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col">TxnID</th> */}
                                            <th  className="customerhead" scope="col">Amount</th>
                                            <th  className="customerhead" scope="col">Date</th>
                                            <th  className="customerhead" scope="col">Status</th>
                                            <th  className="customerhead" scope="col">MerchantReference</th>
                                            <th  className="customerhead" scope="col">Session ID</th>
                                            <th  className="customerhead" scope="col">Manually Added ?</th>
                                        </tr>
                                    </thead>
                                    {data && data.transaction?.map((x, i) => (
                                        <tbody key={i}>
                                            <tr>
                                                <td>{x.amount || "Not available"}</td>
                                                <td>{moment(x.date).local().format("DD-MM-YYYY")}</td>
                                                <td>{x.status || "Not available"}</td>
                                                <td>{x.merchantReference || "Not available"}</td>
                                                <td>{x.sessionid || "Not available"}</td>
                                                <td>{x.isManual ? 'Yes' : 'No'}</td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </Card>
                    </Card>

                </div>
            </div>

        </Container>
    )
}


const mapStateToProps = function (state) {
    return {
        fetchSinglWalletDetails: state.wallet.fetchSingleWallet,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getSingleWalletData: (userid) => dispatch(SingleCustomerWalletAction(userid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingleWallet));