import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../pdf/pdf.css"
import { useTranslation } from 'react-i18next';

// Create Document Component
const RfidReportPdf = (props) => {
  const { t } = useTranslation();
  const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;
  const { data } = props;

  const generatePDF = () => {
    // console.log("props", props)
    // console.log("generatePDF")
    const { data } = props;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const marginTop = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    const org_name = props.org === "All" ? "All" : props.org
    const { organisations } = props.orgdata
    // const organisation = organisations && organisations.filter(or => or.name === org_name)
    // const {address,city,email,phone}=organisation[0]
    const range = "Date range: " + props.start.toLocaleString() + " To " + props.end.toLocaleString()
    const title = "Organisation: " + org_name;
    const org_unit = "Consumed kWh: " + props.reportCountRes?.total_energy
    const rfid_free_charging_energy = "Free charging consumed kWh: " + props.reportCountRes?.free_charging_total_energy
    const org_amt = "Total Revenue: " + props.reportCountRes?.total_revenue
    const free_charging_energy = props.reportCountRes?.free_charging_total_energy;
    const total_kwh = `Total kWh ${(props.reportCountRes?.total_energy - free_charging_energy).toFixed(2)}`;
    const gst_number = "GST Number: " + gstNumber;
    // console.log(total_kwh)

    const headers = [
      ["RFID", "Organisation", "Free charging ?", "Start date", "Amount", "Remaining Amount", "kWh", "Remaining kWh", "Time (in Hrs)", "Remaining time (in Min)"]
    ];


    const bodyData = data && data.map(elt =>
      [
        elt.idtag,                                      // RFID
        elt.operator,                                      // RFID
        elt.free_charging ? "Yes" : "No",               // Free charging?
        elt.start_date,                                 // Start date
        elt.rfid_amount_recharge.toFixed(2),            // Amount
        elt.rfid_wallet_amount.toFixed(2),              // Remaining Amount
        elt.rfid_kwh_recharge.toFixed(2),               // kWh
        elt.rfid_wallet_kwh.toFixed(2),                 // Remaining kWh
        elt.rfid_time_recharge,                         // Time (in Hrs)
        elt.rfid_wallet_time                            // Remaining time (in Min)
      ]);

    // console.log("invoice data", data);
    let content = {
      startY: 100,
      head: headers,
      body: bodyData
    };

    doc.text(title, marginLeft, 40);
    doc.text(range, marginLeft, 60);
    doc.autoTable(content);
    doc.save(props.org + "_" + props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `RFID Report.pdf`)
  }
  const handleButtonClick = async () => {
    // Generate the PDF
    generatePDF();
    // props.onClick();
  };

  return (
    <div>
      <button className='pdfbutton' onClick={handleButtonClick} disabled={data === undefined} type="button">{t("PDF(PARTNER)")}</button>
    </div>
  )
}

export default RfidReportPdf;